import React, { useEffect, useState } from 'react';
import { CircularProgress, Fab, Grid, Typography } from '@mui/material';
import { formatDatetime, generaPDF } from 'functions';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import AlertError from 'components/AlertError/AlertError';
import MUIDataTable from 'mui-datatables';
import { Cancel, Launch, Verified } from '@mui/icons-material';
import axios from 'axios';
import { PictureAsPdf } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ModalPDF from 'components/Modal/ModalPDF';
import CartonPDF from 'controllers/etapas/etaparmp/Carton/CartonPDF';
import PescadoPDF from 'controllers/etapas/etaparmp/Pescado/PescadoPDF';
import EnvaseTapaPDF from 'controllers/etapas/etaparmp/EnvaseTapa/EnvaseTapaPDF';
import PRMPDF from 'controllers/etapas/etapaprm/PRMPDF';
import CocimientoPDF from 'controllers/etapas/etapacocimiento/CocimientoPDF';
import CarrosLimpiezaPDF from 'controllers/etapas/etapaocb/CarrosLimpieza/CarrosLimpiezaPDF';
import OCBLimpiezaPesPDF from 'controllers/etapas/etapaocb/OCBLimpiezaPes/OCBLimpiezaPesPDF';
import MezclaPDF from 'controllers/etapas/etapamezcla/MezclaPDF';
import EvaluacionETPDF from 'controllers/etapas/etapaenlatado/EvaluacionET/EvaluacionETPDF';
import MaterialEnvasePDF from 'controllers/etapas/etapaenlatado/MaterialEnvase/MaterialEnvasePDF';
import TrazabilidadPDF from 'controllers/etapas/etapaenlatado/TrazabilidadProc/TrazabilidadPDF';
import EvaluacionDobleCierrePDF from 'controllers/etapas/etapaenlatado/EvaluacionDobleCierre/EvaluacionDobleCierrePDF';
import PesoPastillaPDF from 'controllers/etapas/etapaenlatado/PesoPastilla/PesoPastillaPDF';
import LiquidosCoberturaPDF from 'controllers/etapas/etapaenlatado/LiquidosCobertura/LiquidosCoberturaPDF';
import PesoNetoPDF from 'controllers/etapas/etapaenlatado/PesoNeto/PesoNetoPDF';
import VerifCodigosPDF from 'controllers/etapas/etapaenlatado/VerifCodigos/VerifCodigosPDF';
import AjusteEngargoladorasPDF from 'controllers/etapas/etapaenlatado/AjusteEngargoladoras/AjusteEngargoladorasPDF';
import LotesLiberadosPDF from 'controllers/etapas/etapacodificado/LotesLiberados/LotesLiberadosPDF';
import CarrosProduccionPDF from 'controllers/etapas/etapacodificado/CarrosProduccion/CarrosProduccionPDF';
import CarrosEtiquetadoPDF from 'controllers/etapas/etapacodificado/CarrosEtiquetado/CarrosEtiquetadoPDF';
import CalidadAguaPDF from 'controllers/etapas/etapaesterilizado/CalidadAgua/CalidadAguaPDF';
import AguaAutoclavesPDF from 'controllers/etapas/etapaesterilizado/AguaAutoclaves/AguaAutoclavesPDF';
import ProcTermicoPDF from 'controllers/etapas/etapaesterilizado/ProcTermico/ProcTermicoPDF';
import BitacoraCalderaPDF from 'controllers/etapas/etapaesterilizado/BitacoraCaldera/BitacoraCalderaPDF';
import EmpaqueTerciarioPDF from 'controllers/etapas/etapaetiquetado/EmpaqueTerciario/EmpaqueTerciarioPDF';
import EvaluacionExternaPTPDF from 'controllers/etapas/etapaetiquetado/EvaluacionExternaPT/EvaluacionExternaPTPDF';
import ProductoNoConformePDF from 'controllers/etapas/etapaetiquetado/ProductoNoConforme/ProductoNoConformePDF';
import EmpaqueProductoPDF from 'controllers/etapas/etapaetiquetado/EmpaqueProducto/EmpaqueProductoPDF';
import AnalisisFisicoquimicoPDF from 'controllers/etapas/etapalaboratorio/AnalisisFisicoquimico/AnalisisFisicoquimicoPDF';
import EvaluacionCuarentenaPDF from 'controllers/etapas/etapalaboratorio/EvaluacionCuarentena/EvaluacionCuarentenaPDF';
import RechazoClientePDF from 'controllers/etapas/etapalaboratorio/RechazoCliente/RechazoClientePDF';
import ReclamacionProveedorPDF from 'controllers/etapas/etapalaboratorio/ReclamacionProveedor/ReclamacionProveedorPDF';
import AlmacenamientoCuarentenaPDF from 'controllers/etapas/etapalaboratorio/AlmacenamientoCuarentena/AlmacenamientoCuarentenaPDF';
import EtapaEmbarquePDF from 'controllers/etapas/etapaembarque/EtapaEmbarquePDF';
import LimpiezaDiariaEtapa6PDF from 'controllers/etapas/etapaocb/OperacionOCB/OperacionOCBPDF';
import LimpiezaDiariaEtapa8PDF from 'controllers/etapas/etapaenlatado/LimpiezaOAE/LimpiezaOAEPDF';
import LimpiezaDiariaEtapa11PDF from 'controllers/etapas/etapaetiquetado/LimpiezaDiaria/LimpiezaDiariaPDF';
import TiempoMuertoEtapa6PDF from 'controllers/etapas/etapaocb/TiempoMuerto/TiempoMuertoPDF';
import TiempoMuertoEtapa8PDF from 'controllers/etapas/etapaenlatado/TiemposMuertos/TiemposMuertosPDF';
import TiempoMuertoEtapa11PDF from 'controllers/etapas/etapaetiquetado/TiemposMuertos/TiemposMuertosPDF';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import logo from './logo.svg';
import useStyles from './styles';
import { BlobProvider } from '@react-pdf/renderer';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function Dashboard() {
  let classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tienePermiso, setTienePermiso] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [folios, setFolios] = useState([]);
  const [formato, setFormato] = useState(null);
  const [document, setDocument] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'Documento_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'FormatoID',
      name: 'Formato_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Folio',
      name: 'Folio',
      options: { filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'vcFolio' },
    { label: 'Etapa', name: 'Etapa' },
    { label: 'Descripción', name: 'vcDescripcionPantalla' },
    { label: 'Elaboró', name: 'NombreEla' },
    { label: 'Fecha Firma Elaboración', name: 'FechaFirmaEla' },
    { label: 'Registró', name: 'NombreReg' },
    { label: 'Fecha Firma Registro', name: 'FechaFirmaReg' },
    {
      label: 'Detalle',
      name: 'detalle',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link
              to={`/app${handleLaunch(
                tableMeta.rowData[0],
                tableMeta.rowData[2],
              )}`}
              target='_blank'
            >
              <Fab
                color='primary'
                size='small'
                // onClick={() => {
                //   handlePDF(tableMeta.rowData[0]);
                // }}
              >
                {/* <PictureAsPdf /> */}
                <Launch />
              </Fab>
            </Link>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab color='primary' size='small'>
              <BlobProvider document={handlePDF(tableMeta.rowData[0])}>
                {({ load, url }) => {
                  setLoading(load);
                  return load ? (
                    <CircularProgress />
                  ) : (
                    <a href={url} target='_blank' disabled={load || !url}>
                      <Fab color='primary' size='small'>
                        <PictureAsPdf style={{ color: 'white' }} />
                      </Fab>
                    </a>
                  );
                }}
              </BlobProvider>
            </Fab>
          );
        },
      },
    },
    {
      label: 'Firmar',
      name: 'firmar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                handleAccion(tableMeta.rowData[0], true);
              }}
            >
              <Verified />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Cancelar',
      name: 'cancelar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                handleAccion(tableMeta.rowData[0], false);
              }}
            >
              <Cancel />
            </Fab>
          );
        },
      },
    },
  ];

  const prueba = (id) => {
    return (
      <BlobProvider document={handlePDF(id)}>
        {({ loading, url }) => {
          return loading ? (
            <CircularProgress />
          ) : (
            <a href={url} target='_blank' disabled={loading || !url}>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  // setIdSel(tableMeta.rowData[0]);
                  // dispatch({ type: 'OPEN_INPUT_MODAL' });
                }}
              >
                <PictureAsPdf style={{ color: 'white' }} />
              </Fab>
            </a>
          );
        }}
      </BlobProvider>
    );
  };

  const handleLaunch = (id, folio) => {
    // const documento = folios.filter(
    //   (documento) => documento.Documento_ID === id,
    // );
    // return `${documento[0].url}${folio}`;

    // console.log(folios);

    const documento = folios.filter(
      (documento) => documento.Documento_ID === id,
    );
    if (
      documento[0].Etapa_ID === '6' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[0].replace('$=6:', '')}${id}`;
    }
    if (
      documento[0].Etapa_ID === '8' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[1].replace('8:', '')}${id}`;
    }
    if (
      documento[0].Etapa_ID === '11' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[2].replace('11:', '')}${id}`;
    }
    return `${documento[0].url}${folio}`;
  };

  const handlePDF = (id) => {
    // dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = folios.find((x) => x.Documento_ID === id);
    // setFormato(getPDF(detalle.Formato_ID, detalle.Etapa_ID, detalle.Folio));
    return getPDF(detalle.Formato_ID, detalle.Etapa_ID, detalle.Folio);
  };

  const handleAccion = (id, esCerrar) => {
    let detalle = folios.find((x) => x.Documento_ID === id);
    const folio = {
      userid: sessionStorage.getItem('user_id'),
      id: detalle.Folio || 0,
      horacierre: formatDatetime(new Date()) || null,
      vcfolio: detalle.vcFolio || '',
      formatoid: detalle.Formato_ID || 0,
      etapaid: detalle.Etapa_ID || 0,
      estatusid: esCerrar ? 22 : 23,
    };

    Swal.fire({
      title: esCerrar
        ? '¿Está seguro de cerrar el folio?'
        : '¿Está seguro de cancelar el folio?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .post('/consultas/cierrefolio', folio)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text:
                folio.estatusid === 22
                  ? '¡Se ha cerrado el folio exitosamente!'
                  : '¡Se ha cancelado el folio exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            getFolios();
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      }
    });
  };

  const getFolios = () => {
    setLoading(true);

    const user_id = sessionStorage.getItem('user_id');

    axios
      .get(`/consultas/foliospteverifica/${user_id}`, {})
      .then((res) => {
        console.log(res.data.data);
        setFolios(res.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        // setError(error);
        setLoading(false);
      });
  };

  const getPDF = (pantalla, etapa, idSel) => {
    switch (pantalla) {
      case '1':
        return <CartonPDF id={idSel} />;
      case '2':
        return <PescadoPDF id={idSel} />;
      case '3':
        return <PescadoPDF id={idSel} />;
      case '4':
        return <EnvaseTapaPDF id={idSel} />;
      case '5':
        return <PRMPDF id={idSel} />;
      case '6':
        return <CocimientoPDF id={idSel} />;
      case '7':
        return <CarrosLimpiezaPDF id={idSel} />;
      case '8':
        return <OCBLimpiezaPesPDF id={idSel} />;
      case '9':
        return <MezclaPDF id={idSel} />;
      case '10':
        return <EvaluacionETPDF id={idSel} />;
      case '11':
        return <MaterialEnvasePDF id={idSel} />;
      case '12':
        return <TrazabilidadPDF id={idSel} />;
      case '13':
        return <EvaluacionDobleCierrePDF id={idSel} />;
      case '14':
        return <PesoPastillaPDF id={idSel} />;
      case '15':
        return <LiquidosCoberturaPDF id={idSel} />;
      case '16':
        return <PesoNetoPDF id={idSel} />;
      case '17':
        return <VerifCodigosPDF id={idSel} />;
      case '18':
        return <AjusteEngargoladorasPDF id={idSel} />;
      case '19':
        return <LotesLiberadosPDF id={idSel} />;
      case '20':
        return <CarrosProduccionPDF id={idSel} />;
      case '21':
        return <CarrosEtiquetadoPDF id={idSel} />;
      case '22':
        return <CalidadAguaPDF id={idSel} />;
      case '23':
        return <AguaAutoclavesPDF id={idSel} />;
      case '24':
        return <ProcTermicoPDF id={idSel} />;
      case '25':
        return <BitacoraCalderaPDF id={idSel} />;
      case '26':
        return <EmpaqueTerciarioPDF id={idSel} />;
      case '27':
        return <EvaluacionExternaPTPDF id={idSel} />;
      case '28':
        return <ProductoNoConformePDF id={idSel} />;
      case '29':
        return <EmpaqueProductoPDF id={idSel} />;
      case '30':
        return <AnalisisFisicoquimicoPDF id={idSel} />;
      case '31':
        return <EvaluacionCuarentenaPDF id={idSel} />;
      case '32':
        return <RechazoClientePDF id={idSel} />;
      case '33':
        return <ReclamacionProveedorPDF id={idSel} />;
      case '34':
        return <AlmacenamientoCuarentenaPDF id={idSel} />;
      case '35':
        return <EtapaEmbarquePDF id={idSel} />;
      case '36':
        switch (etapa) {
          case '6':
            return <LimpiezaDiariaEtapa6PDF id={idSel} />;
          case '8':
            return <LimpiezaDiariaEtapa8PDF id={idSel} />;
          case '11':
            return <LimpiezaDiariaEtapa11PDF id={idSel} />;
          default:
            return null;
        }
      case '37':
        switch (etapa) {
          case '6':
            return <TiempoMuertoEtapa6PDF id={idSel} />;
          case '8':
            return <TiempoMuertoEtapa8PDF id={idSel} />;
          case '11':
            return <TiempoMuertoEtapa11PDF id={idSel} />;
          default:
            return null;
        }

      default:
        return null;
    }
  };

  useEffect(() => {

  //  if(sessionStorage.getItem('ref')===0) {
  //   sessionStorage.setItem('ref', 1);
  //   window.location.reload();
  //   return;
  //  }

    setTienePermiso(
      sessionStorage.getItem('level') === '1' ||
        sessionStorage.getItem('level') === '2'
        ? true
        : false,
    );
    getFolios();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : tienePermiso ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  {error ? <AlertError message={error} /> : ''}
                  <MUIDataTable
                    data={folios}
                    columns={columns}
                    options={{ responsive: 'standard', selectableRows: 'none' }}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <ModalPDF
            state={state}
            dispatch={dispatch}
            loading={loading}
            pdf={formato}
          />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.logotypeContainer}>
              <Typography className={classes.logotypeText}>
                Bienvenido a PessaSys
              </Typography>
              <img src={logo} alt='logo' className={classes.logotypeImage} />
              {/* <Typography className={classes.logotypeText}>PessaSys</Typography> */}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Dashboard;

import React, { useEffect, useState } from 'react';  
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import {Autocomplete,TextField, Button, Fab ,Grid } from '@mui/material';
import axios from 'axios';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom'; 
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { formatDate } from 'functions';
 import Swal from 'sweetalert2';
 import { Delete } from '@mui/icons-material';
 import { 
 
  IconButton, 
  Tooltip, 
} from '@mui/material';
 

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};


function Permisos() {
  const { id } = useParams(); 
  const [loading, setLoading] = useState(); 
  const [selectedRows, setSelectedRows] = useState([]);
  const initialRegistro = {
    userid: sessionStorage.getItem('user_id'), 
    id: id, 
    rol: null, 
    nivel: null, 
    dateini:null,
    dateend:null,
    etapa1:false,
    etapa2:false,
    etapa3:false,
    etapa4:false,
    etapa5:false,
    etapa6:false,
    etapa7:false,
    etapa8:false,
    etapa9:false,
    etapa10:false,
    etapa11:false,
    etapa12:false,
    etapa13:false,
    setting:false,  
    readonly:false
  };


  const [registro, setRegistro] = useState({ ...initialRegistro });
  const [roles, setRoles] = useState([]);
  const [niveles] = useState([ 
                          { Id: 0, Name: 'Sin Nivel' }, 
                          { Id: 1, Name: 'Nivel 1' }, 
                          { Id: 2, Name: 'Nivel 2' },
                          { Id: 3, Name: 'Nivel 3' },
                          { Id: 4, Name: 'Nivel 4' },
                          { Id: 5, Name: 'Nivel 5' }
                          ]);


  const [documentos, setDocumentos] = useState([]);

  const columns = [
    { label: 'ID', name: 'id' ,  options: { display: false, filter: false, viewColumns: false }}, 
    { label: 'Rol', name: 'roldesc' },
    { label: 'Nivel', name: 'niveldesc' },
    { label: 'Fecha Inicio', name: 'fechaini' },
    { label: 'Fecha Fin', name: 'fechaend' },
    { label: 'Registro Materia Prima', name: 'etapa1' },
    { label: 'Planificación', name: 'etapa2' },
    { label: 'Descongelado', name: 'etapa3' },
    { label: 'Eviscerado', name: 'etapa4' },
    { label: 'Cocimiento', name: 'etapa5' },
    { label: 'OCB', name: 'etapa6' },
    { label: 'Mezcla', name: 'etapa7' },
    { label: 'Enlatado', name: 'etapa8' },
    { label: 'Codificado', name: 'etapa9' },
    { label: 'Esterilizado', name: 'etapa10' },
    { label: 'Etiquetado', name: 'etapa11' },
    { label: 'Laboratorio', name: 'etapa12' },
    { label: 'Embarque', name: 'etapa13' },
    { label: 'Modulo Admin', name: 'setting' },
    { label: 'Consulta', name: 'readonly' },
    
  
     
  ];

  const getRoles = () => {
    setLoading(true);
    try {
      axios
        .get('catalogo/roles', {})
        .then((res) => {
          if (res !== null) {
            setRoles(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
     
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };



  const getDocumentos = () => {
    setLoading(true);

    axios
      .get(`/administracion/usuarios/permisos/${id}`, {})
      .then((res) => {

        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {

        // setError(error);
        setLoading(false);
      });
  };

  const rolesProps = {
    options: roles.map((option) => ({
      id: option.id,
      name: option.nombre,
    })),
    getOptionLabel: (option) => option.name,
  };

  const nivelesProps = {
    options: niveles.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const handleEtapa1Change = (event) => {
    setRegistro({
      ...registro,
      etapa1: event.target.checked,
    }); 
  };

  const handleEtapa2Change = (event) => {
    setRegistro({
      ...registro,
      etapa2: event.target.checked,
    });
  }; 

  const handleEtapa3Change = (event) => { 
    setRegistro({
      ...registro,
      etapa3: event.target.checked,
    });
  };

  const handleEtapa4Change = (event) => {
    setRegistro({
      ...registro,
      etapa4: event.target.checked,
    });
  };

  const handleEtapa5Change = (event) => {
    setRegistro({
      ...registro,
      etapa5: event.target.checked,
    });
  };

  const handleEtapa6Change = (event) => {
    setRegistro({
      ...registro,
      etapa6: event.target.checked,
    });
  };

  const handleEtapa7Change = (event) => {
    setRegistro({
      ...registro,
      etapa7: event.target.checked,
    });
  };

  const handleEtapa8Change = (event) => {
    setRegistro({
      ...registro,
      etapa8: event.target.checked,
    });
  };

  const handleEtapa9Change = (event) => {
    setRegistro({
      ...registro,
      etapa9: event.target.checked,
    });
  };

  const handleEtapa10Change = (event) => {
    setRegistro({ 
      ...registro,
      etapa10: event.target.checked,
    });

  };  

  const handleEtapa11Change = (event) => {
    setRegistro({
      ...registro,
      etapa11: event.target.checked,
    });
  };

  const handleEtapa12Change = (event) => {
    setRegistro({
      ...registro,
      etapa12: event.target.checked,
    });
  };

  const handleEtapa13Change = (event) => {
    setRegistro({
      ...registro,
      etapa13: event.target.checked,
    });
  };

  const handleSettingChange = (event) => {
    setRegistro({
      ...registro,
      setting: event.target.checked,
    });
  };

  const handleReadOnlyChange = (event) => {
    setRegistro({
      ...registro,
      readonly: event.target.checked,
    });
  };

 
 const handleSubmit = () => {
    setLoading(true);
    axios
      .post(`/administracion/usuarios/permisos`, registro)  
      .then((res) => {
        setLoading(false);
        setRegistro({ ...initialRegistro });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumentos();
        
      })
      .catch((error) => {
   
        setLoading(false);
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      });
  };

  
  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/administracion/delete/usuarios/permisos/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };


  

       
  useEffect(() => {
    getRoles();
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Widget>
                <Grid container spacing={2}>
                  <Grid  item xs={3}>
                
                      <Autocomplete
                              fullWidth
                              
                              id='rolid'
                              {...rolesProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Rol'
                                />
                              )}
                              value={registro.rol || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  rol: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                  </Grid>
                  <Grid  item xs={3}>
                
                      <Autocomplete
                              fullWidth
                              
                              id='nivelid'
                              {...nivelesProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Nivel'
                                />
                              )}
                              value={registro.nivel || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  nivel: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                  </Grid>


                  <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                required
                                 
                                id='fechaini'
                                name='fechaini'
                                label='Fecha Inicio'
                                format='DD/MM/YYYY'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }} 
                                defaultValue={registro.dateini? new Date(registro.dateini):null}
                                value={registro.dateini?  dayjs(new Date(registro.dateini)):null}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    dateini: formatDate(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                  </Grid>

                  <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                 
                                id='fechafin'
                                name='fechafin'
                                label='Fecha Final'
                                format='DD/MM/YYYY'
                                slotProps={{
                                  textField: {
                                    fullWidth: true, 
                                  },
                                }}
                                defaultValue={registro.dateend? new Date(registro.dateend):null}
                                value={registro.dateend?  dayjs(new Date(registro.dateend)):null}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    dateend: formatDate(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                  </Grid>


                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa1}
                        onChange={handleEtapa1Change}
                      />
                      Registro Materia Prima
                    </label>
                  </Grid>
                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa2}
                        onChange={handleEtapa2Change}
                      />
                      Planificación
                    </label>
                  </Grid>
                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa3}
                        onChange={handleEtapa3Change}
                      />
                      Descongelado
                    </label>
                  </Grid>
                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa4}
                        onChange={handleEtapa4Change}
                      />
                     Eviscerado
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa5}
                        onChange={handleEtapa5Change}
                      />
                     Cocimiento
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa6}
                        onChange={handleEtapa6Change}
                      />
                     OCB
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa7}
                        onChange={handleEtapa7Change}
                      />
                     Mezcla
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa8}
                        onChange={handleEtapa8Change}
                      />
                     Enlatado
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa9}
                        onChange={handleEtapa9Change}
                      />
                     Codificado
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa10}
                        onChange={handleEtapa10Change}
                      />
                     Esterilizado
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa11}
                        onChange={handleEtapa11Change}
                      />
                     Etiquetado
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa12}
                        onChange={handleEtapa12Change}
                      />
                     Laboratorio
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.etapa13}
                        onChange={handleEtapa13Change}
                      />
                     Embarque
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.setting}
                        onChange={handleSettingChange}
                      />
                     Modulo Administración
                    </label>
                  </Grid>

                  <Grid  item xs={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={registro.readonly}
                        onChange={handleReadOnlyChange}
                      />
                     Solo Consultar
                    </label>
                  </Grid> 

                  <Grid item xs={12}>
                  <Button color='primary' variant='contained' type='submit' sx={{ float: 'right' }}>
                    Agregar permiso
                  </Button>

                  </Grid>




                </Grid>

                
              </Widget>
              </form>
            </Grid>

            <Grid item xs={12}>
            <Widget>

              <MUIDataTable 

                data={documentos}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDelete(
                              documentos[selectedRows.data[i].dataIndex].id,
                            );
                          }
                          getDocumentos();
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
              

                
              </Widget>
            </Grid>
        </Grid>
          
        </>
      )}
    </>
  );
}

export default Permisos;
